
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { getPlatformIcon } from "@/shared/utils/platformIcons";
import { maskWalletAddress } from "@/shared/utils/stringHelper";

export default defineComponent({
  emits: ["on-back"],
  props: {
    message: {
      type: String,
    },
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
  methods: { getPlatformIcon, maskWalletAddress },
  components: {},
});
