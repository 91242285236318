import gql from "graphql-tag";

export const applicationDetailsGql = gql`
  query ApplicationDetails($input: ApplicationDetailsInput!) {
    applicationDetails(input: $input) {
      ... on ApplicationDetails {
        clientId
        name
        logo40
        logo80
        logo400
        category
        redirectUris
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
