import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "oauth-authorize" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AuthorizationMain = _resolveComponent("AuthorizationMain")!
  const _component_AuthorizationFailed = _resolveComponent("AuthorizationFailed")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.authorizationStatus === _ctx.AuthorizationStatuses.initial)
      ? (_openBlock(), _createBlock(_component_AuthorizationMain, {
          key: 0,
          onOnAuthorize: _ctx.handleSubmit,
          loadingAuthorization: _ctx.loadingAuthorization,
          loading: _ctx.loading,
          platformDetails: _ctx.platformDetails,
          onOnCancel: _ctx.handleCloseWindow
        }, null, 8, ["onOnAuthorize", "loadingAuthorization", "loading", "platformDetails", "onOnCancel"]))
      : (_ctx.authorizationStatus === _ctx.AuthorizationStatuses.error)
        ? (_openBlock(), _createBlock(_component_AuthorizationFailed, {
            key: 1,
            onOnBack: _ctx.handleCloseWindow,
            message: _ctx.authorizationStatusMessage
          }, null, 8, ["onOnBack", "message"]))
        : _createCommentVNode("", true)
  ]))
}