import { computed } from "vue";
import { useQuery } from "@vue/apollo-composable";
import { applicationDetailsGql } from "@/api/oauth/applicationDetails";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import {
  ApplicationDetailsVariables,
  ApplicationDetails_applicationDetails,
  ApplicationDetails_applicationDetails_ApplicationDetails,
} from "@/api/oauth/__generated__/ApplicationDetails";
import { ApplicationDetailsInput } from "__generated__/globalTypes";
import isEmpty from "lodash/isEmpty";
import noop from "lodash/noop";
import {
  HideIfErrorCode,
  parseGqlError,
} from "@/shared/utils/graphql/errorHandler";

/**
 * @param input - application details input
 * @param onErrorCallback - callback function to be called when error is thrown
 */
export const useApplicationDetails = (
  input: ApplicationDetailsInput,
  onErrorCallback = noop
) => {
  const { result, loading, onError } = useQuery<
    ApplicationDetails_applicationDetails,
    ApplicationDetailsVariables
  >(
    applicationDetailsGql,
    { input },
    // do not query if any of the required input variable is empty
    { enabled: !!input.clientId && !!input.redirectUri }
  );

  const applicationDetails = computed(() => {
    /**
     * When parsing the response, do not show any error via toast with HideIfErrorCode.ALL_ERRORS
     * This is to avoid showing the error message twice because the error is handled by the onErrorCallback
     */
    const parsedResponse =
      parseGqlResponse<ApplicationDetails_applicationDetails_ApplicationDetails>(
        "ApplicationDetails",
        result.value,
        HideIfErrorCode.ALL_ERRORS
      );

    console.log("applicationDetails: parsedResponse", parsedResponse);

    if (!isEmpty(parsedResponse.error?.errors)) {
      /**
       * Get error message but do not show any error via toast with HideIfErrorCode.ALL_ERRORS
       */
      onErrorCallback(
        parseGqlError(parsedResponse.error, HideIfErrorCode.ALL_ERRORS).message
      );
    }

    // return application details
    return parsedResponse.data;
  });

  /**
   * onError hooks when there's an internal error
   * call with empty param to show generic error message
   */
  onError(() => onErrorCallback());

  return {
    loading,
    applicationDetails,
    onError,
  };
};
