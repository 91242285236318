import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "authorization-main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlatformIcon = _resolveComponent("PlatformIcon")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_MultiAccountSelector = _resolveComponent("MultiAccountSelector")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_space, {
      class: "p-8 w-100 justify-center",
      size: "large",
      align: "center"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_PlatformIcon, {
          class: "authorization-main__logo",
          shape: "square",
          name: _ctx.platformDetails.name ?? '',
          logo: _ctx.platformDetails.logoSrc ?? '',
          loading: _ctx.loading,
          "fallback-initial-icon": ""
        }, null, 8, ["name", "logo", "loading"]),
        _createVNode(_component_PlatformIcon, {
          class: "authorization-main__logo rounded-none",
          shape: "square",
          name: "avatarada",
          loading: _ctx.loading
        }, null, 8, ["loading"])
      ]),
      _: 1
    }),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_a_skeleton, {
          key: 0,
          class: "authorization-main__title--loading",
          active: "",
          paragraph: false
        }))
      : (_openBlock(), _createBlock(_component_a_typography_title, {
          key: 1,
          level: 2,
          class: "text-center font-medium"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("Authorize {platform}", { platform: _ctx.platformDetails.name })), 1)
          ]),
          _: 1
        })),
    _createVNode(_component_a_typography_paragraph, { class: "m-0" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("{merchantBrand} will share only public data", {
          merchantBrand: _ctx.config.merchantBrand,
        })) + ": ", 1)
      ]),
      _: 1
    }),
    _createElementVNode("ul", null, [
      _createElementVNode("li", null, _toDisplayString(_ctx.t("staking key hash")), 1),
      _createElementVNode("li", null, _toDisplayString(_ctx.t("name")), 1),
      _createElementVNode("li", null, _toDisplayString(_ctx.t("profile picture")), 1)
    ]),
    _createVNode(_component_a_typography_paragraph, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("Rest of the data will be securely stored.")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_a_typography_paragraph, { class: "uppercase mb-2 small" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("Select the account")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_MultiAccountSelector, {
      class: "authorization-main__account-selector mb-5",
      mode: _ctx.MultiAccountSelectorMode.external,
      disabled: _ctx.loadingAuthorization
    }, null, 8, ["mode", "disabled"]),
    _createVNode(_component_a_button, {
      loading: _ctx.loadingAuthorization,
      disabled: _ctx.loading,
      block: "",
      class: "ant-btn-black mb-4",
      onClick: _ctx.handleSubmit
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("Authorize {walletAddress}", {
          walletAddress: _ctx.maskWalletAddress(_ctx.walletAddress),
        })), 1)
      ]),
      _: 1
    }, 8, ["loading", "disabled", "onClick"]),
    _createVNode(_component_a_button, {
      block: "",
      class: "mb-4",
      disabled: _ctx.loadingAuthorization || _ctx.loading,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-cancel')))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("Cancel")), 1)
      ]),
      _: 1
    }, 8, ["disabled"]),
    _createVNode(_component_a_typography_paragraph, { class: "small lighter text-center" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("Authorizing will redirect to {url}", { url: _ctx.platformDetails.url })), 1)
      ]),
      _: 1
    })
  ]))
}