import gql from "graphql-tag";

export const authorizeGql = gql`
  mutation Authorize($input: AuthorizeInput!) {
    authorize(input: $input) {
      ... on RedirectUri {
        redirectUri
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
