
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { getPlatformIcon } from "@/shared/utils/platformIcons";
import { maskWalletAddress } from "@/shared/utils/stringHelper";
import { useAuthorization } from "@/oauth/components/composables/useAuthorization";
import AuthorizationMain from "./AuthorizationMain.vue";
import AuthorizationFailed from "./AuthorizationFailed.vue";
import { useRouter } from "vue-router";
import { useApplicationDetails } from "@/oauth/components/composables/useApplicationDetails";
import { isEmpty } from "lodash";

export enum AuthorizationStatuses {
  initial = "initial",
  success = "success",
  error = "error",
}

export default defineComponent({
  props: {},
  setup() {
    const { t } = useI18n();
    const route = useRouter();
    const query = computed(() => route.currentRoute.value.query);

    /**
     * Get Query Parameters
     * Cast to string to avoid type errors using "as", <string> casting will not work because of type mismatch
     */
    const client_id = computed(
      () => route.currentRoute.value.query.client_id as string
    );
    const redirect_uri = computed(
      () => route.currentRoute.value.query.redirect_uri as string
    );

    const authorizationStatus = ref(AuthorizationStatuses.initial);
    const authorizationStatusMessage = ref<string>("");

    const { handleAuthorize, loading: loadingAuthorization } =
      useAuthorization();

    /**
     * Error callback for the authorization request
     *
     * The reason for callback is because there are no function call
     * for the query and called on mount automatically.
     *
     * This callback error handler is also used on useQuery's error hooks
     */
    const onErrorCallback = (errorMessage = "") => {
      authorizationStatus.value = AuthorizationStatuses.error;

      /**
       * Only set the message if it is not empty
       * This is to avoid setting the message to an empty string which is the default
       */
      if (errorMessage) {
        authorizationStatusMessage.value = errorMessage;
      }
    };

    /**
     * This query is called on mount and is used to get the application details
     * Unless first parameter is empty
     */
    const { applicationDetails, loading } = useApplicationDetails(
      {
        clientId: client_id.value,
        redirectUri: redirect_uri.value,
      },
      onErrorCallback
    );

    const handleCloseWindow = () => {
      try {
        window.close();
      } catch (error) {
        console.error("handleCloseWindow:error", error);
      }
    };

    onMounted(() => {
      /**
       * Validated query first before showing authorization UI
       * Application details fetch will not trigger without these required inputs
       */
      let errors: string[] = [];
      if (!client_id.value) {
        errors.push(t("Client ID is missing"));
      }
      if (!redirect_uri.value) {
        errors.push(t("Redirect URI is missing"));
      }

      if (!isEmpty(errors)) {
        console.error(errors);
        /**
         * This will show as:
         *
         * Something went wrong.
         * Error 1
         * ...
         */
        onErrorCallback([t("Something went wrong."), ...errors].join("\n"));
      }
    });

    const handleSubmit = async () => {
      try {
        /**
         * Follow redirect uri so it can save the oauth code
         * Open it in a new tab so it can close the current tab/window
         */
        const response = await handleAuthorize({
          clientId: query.value.client_id,
          redirectUri: query.value.redirect_uri,
        });

        if (response?.redirectUri) {
          window.location.href = response?.redirectUri;
        }
      } catch (error) {
        console.error(error);
        // set the authorization status to error
        authorizationStatus.value = AuthorizationStatuses.error;
      } finally {
        /**
         * After a few seconds if the user didn't close the oauth window
         * Automatically close it, regardless of the authorization status
         *
         * "Back to provider" button just closes the oauth window
         */
        setTimeout(handleCloseWindow, 3000);
      }
    };

    return {
      t,
      loadingAuthorization,
      loading,
      handleSubmit,
      handleCloseWindow,
      authorizationStatus,
      authorizationStatusMessage,
      AuthorizationStatuses,
      platformDetails: computed(() => ({
        id: client_id.value,
        name: applicationDetails.value?.name,
        logoSrc: applicationDetails.value?.logo40,
        clientId: query.value.client_id,
        redirectUri: redirect_uri.value,
        url: redirect_uri.value,
      })),
    };
  },
  methods: { getPlatformIcon, maskWalletAddress },
  components: {
    AuthorizationMain,
    AuthorizationFailed,
  },
});
