
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import MultiAccountSelector, {
  MultiAccountSelectorMode,
} from "@/shared/components/MultiAccountSelector/MultiAccountSelector.vue";
import { maskWalletAddress } from "@/shared/utils/stringHelper";
import { useAccount } from "@/shared/composables/Profile/useAccount";
import { config } from "@/shared/utils/config";
import PlatformIcon from "@/shared/components/Icons/PlatformIcon.vue";

export default defineComponent({
  components: { MultiAccountSelector, PlatformIcon },
  props: {
    loadingAuthorization: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    platformDetails: {
      type: Object,
      required: true,
    },
  },
  emits: ["on-authorize", "on-cancel"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const { selectedUser } = useAccount();

    const handleSubmit = () => {
      emit("on-authorize");
    };

    return {
      t,
      config,
      handleSubmit,
      walletAddress: computed(() => selectedUser.value.wallet.stakingKeyHash),
      MultiAccountSelectorMode,
      selectedUser,
    };
  },
  methods: { maskWalletAddress },
});
