import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "oauth-authorize" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_alert = _resolveComponent("a-alert")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_space, {
      class: "p-8 w-100 justify-center",
      size: "large",
      align: "center"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_avatar, {
          shape: "square",
          size: 48,
          src: _ctx.getPlatformIcon('avatarada')
        }, null, 8, ["src"])
      ]),
      _: 1
    }),
    _createVNode(_component_a_typography_title, {
      level: 2,
      class: "mt-4 text-center font-medium"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("Authorization failed")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_a_typography_paragraph, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("Due to mistake authorization failed")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_a_alert, {
      class: "mb-4 whitespace-pre-line",
      message: _ctx.message || _ctx.t('Something went wrong. Please try again later.'),
      type: "error",
      "show-icon": false
    }, null, 8, ["message"]),
    _createVNode(_component_a_button, {
      type: "black",
      block: "",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-back')))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("Back to provider")), 1)
      ]),
      _: 1
    })
  ]))
}