import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
import { computed } from "vue";
import isEmpty from "lodash/isEmpty";
import {
  Authorize,
  AuthorizeVariables,
  Authorize_authorize_RedirectUri,
} from "@/api/oauth/__generated__/Authorize";
import { authorizeGql } from "@/api/oauth/authorize";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { apiErrorCodes } from "@/shared/utils/constants";

export function useAuthorization() {
  const authorizeMutation = useCustomMutation<Authorize, AuthorizeVariables>(
    authorizeGql
  );

  const handleAuthorize = async ({ clientId, redirectUri }) => {
    const authorizeResponse = await authorizeMutation.mutate({
      input: {
        clientId,
        redirectUri,
      },
    });

    const parsedResponse = parseGqlResponse<Authorize_authorize_RedirectUri>(
      "RedirectUri",
      authorizeResponse,
      apiErrorCodes.INTERNAL_ERROR
    );

    console.log("parsedResponse", parsedResponse);

    /**
     * throw error and set status to error
     */
    if (!isEmpty(parsedResponse.error?.errors) || !authorizeResponse) {
      throw new Error("Failed to authorize");
    }

    return parsedResponse.data;
  };

  return {
    handleAuthorize,
    loading: computed(() => authorizeMutation.loading.value),
  };
}
